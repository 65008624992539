/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../Context/AuthContext';
import { HubContext } from '../../Context/HubContext.js';
import { useParams } from 'react-router-dom';
import { Req, Request } from '../../scripts/Request';
import { Cache} from '../../scripts/Cache';
import { WaitObject } from '../../scripts/Wait';
import ReactGA from 'react-ga';
import videojs from 'video.js';
import VideoJS from '../VideoPlayer/VideoJS';
import './index.css';


export default function Index() {
    const component_name = "player";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [affiliated, setAffiliated] = useState("");
    const [doc, setDoc] = useState("");
    const { Hub } = useContext(HubContext);
    const timerIdleRef = useRef(new Date());
    const loadPrestineRef = useRef(false);
    const playerRef = useRef(null);


    //#region Affiliate
    let { Affiliate } = useParams();
    let { Id } = useParams();
    //#endregion Affiliate


    //#region Effects
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);

        if(Affiliate!=="" 
            && Affiliate!==null 
            &&Affiliate!==undefined 
            &&Affiliate.toLowerCase()!=="login"
            &&Affiliate.toLowerCase()!=="contato"
            &&Affiliate.toLowerCase()!=="cadastro"
            &&Affiliate.toLowerCase()!=="index.html") {
                Cache.Set("affiliate", Affiliate);
        }

        let wait = setTimeout(() => {
            if(!loadPrestineRef.current) {
                loadPrestineRef.current = true;
                Init();
            }
        }, 200);

        return () => {
            clearInterval(wait);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    Hub.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);
    //#endregion Effects


    //#region Init
    const Init = () => {    
        LoadVideo();
    }
    //#endregion Init


    //#region Loaders
    const LoadVideo = async () => {
        let _result = await Req("api/Contato/Video?id="+Id, "GET", "", "");
        setDoc(_result)
    }
    //#endregion Loaders


    //#region Player
    const handlePlayerReady = (player) => {
        playerRef.current = player;
    
        // You can handle player events here, for example:
        player.on('waiting', () => {
          videojs.log('player is waiting');
        });
    
        player.on('dispose', () => {
          videojs.log('player will dispose');
        });
    };


    const getMime = (file) => {
        let mime = file.split(".");
        console.log();
        return "video/"+ mime[(mime.length-1)].toString().toLowerCase();
    }
    //#endregion Player

    
    return (
        <>
        <div className="iq-blog-section overview-block-ptb">
            <div className="container">

                <div className="row">
                    <div className="col-lg-8 col-sm-12 mt-lg-0 mt-5">
                    <article id="post-218"
                        className="post-218 post type-post status-publish format-standard has-post-thumbnail hentry category-marketing tag-business tag-marketing">
                        <div className="iq-blog-box">
                            
                            <div className="iq-blog-detail">
                                
                                <div className="blog-content">
                                <br />
                                    {
                                        doc != "" && (
                                            <center>
                                            <div className="mt_card_item">
                                               <VideoJS options={{
                                                    controls: true,
                                                    responsive: true,
                                                    fluid: true,
                                                    sources: [{
                                                    src: 'https://painel.sunundrive.com/storage/material_promocional/' + doc,
                                                    type: getMime(doc)
                                                    }]
                                                }} onReady={handlePlayerReady} />
                                            </div>
                                            </center>
                                        )
                                    }


                                    <br />
                                </div>

                            </div>
                        </div>
                       
                    </article>
                    </div>


                    <div className="col-lg-4 col-sm-12 mt-lg-0 mt-5">
                    <aside id="secondary" className="widget-area" aria-label="Blog Sidebar">
                        
                        <div id="recent-posts-2" className="widget widget_recent_entries">
                            <img src="/images/dispositivos.png" className="img-fluid" alt="sobre o SUNUNDRIVE" /> <br /><br />
                            Transferência rápida e eficiente de seu dispositivo para nossa nuvem.
                        </div>


                        <div id="recent-posts-2" className="widget widget_recent_entries">
                            <img src="/images/seguranca.png" className="img-fluid" alt="sobre o SUNUNDRIVE" /> 
                            Segurança de ponta com alta disponibilidade para seus dados mais valiosos.
                        </div>

                        <div id="recent-posts-2" className="widget widget_recent_entries">
                            <img src="/images/renda.jpg" className="img-fluid" alt="sobre o SUNUNDRIVE" /> 
                            Ganhe renda extra mensal com nosso programa de afiliados por indicações que você faz de nossa plataforma.
                        </div>

                        <div id="recent-posts-2" className="widget widget_recent_entries">
                            <img src="/images/marketing.png" className="img-fluid" alt="sobre o SUNUNDRIVE" /> <br /><br />
                            Expanda suas oportunidades com nosso sistema de marketing multinível com até 5 níveis de bonificação por vendas diretas.
                        </div>
                    </aside>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}